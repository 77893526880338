import { Link, navigate } from "gatsby"
import qs from "qs"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { Flex, Text } from "rebass"
import Button from "../../components/button"
import InputField from "../../components/input"
import LoginLayout from "../../components/login-layout"
import SEO from "../../components/seo"
import Spinner from "../../components/spinner"
import { useAuth } from "../../context/auth"
import MedusaClient from "../../services/api"

const IndexPage = ({ location }) => {
  const [loading, setLoading] = useState(false)
  const auth = useAuth()
  const { register, handleSubmit } = useForm()

  const [, ...search] = location.search
  const { cli_tok, req_tok } = qs.parse(search)

  const handleLogin = data => {
    setLoading(true)
    auth
      .handleLogin("email", data)
      .then(async () => {
        await MedusaClient.cliAuth.authenticate(cli_tok, req_tok)
        navigate("/cli-login/done")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <LoginLayout pt={3}>
      <SEO title="Email Login" />
      <Text
        onClick={() => navigate(`/cli-login${location.search}`)}
        sx={{
          cursor: "pointer",
          fontSize: 0,
          color: "link",
          fontWeight: 500,
          ":hover": {
            color: "medusa"
          }
        }}
        mt={-2}
      >
        Back
      </Text>
      <Text mb={4} fontWeight="bold" fontSize={4}>
        Sign in with Email
      </Text>
      {loading ? (
        <Flex justifyContent="center">
          <Spinner dark width="20px" height="20px" />
        </Flex>
      ) : (
        <form onSubmit={handleSubmit(handleLogin)}>
          <InputField
            mb={3}
            label="Email"
            name="email"
            ref={register}
            boldLabel={true}
          />
          <InputField
            type="password"
            label="Password"
            boldLabel={true}
            name="password"
            ref={register}
          />
          <Button type="submit" variant={"cta"} mt={4} width={1}>
            Continue
          </Button>
        </form>
      )}
      <Text fontSize={0}>
        Don't have an account?{" "}
        <Link
          to={`/signup?${qs.stringify({
            cli_tok: cli_tok,
            req_tok: req_tok,
            next: "cli"
          })}`}
        >
          Sign up
        </Link>
      </Text>
    </LoginLayout>
  )
}

export default IndexPage
